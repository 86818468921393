<div [id]="getId()">
    <div class="container">
        <div class="row">
            <app-info-block class="col-md-9 col-sm-9"
                            [title]="'ABOUT.TITLE' | translate"
                            [text]="'ABOUT.TEXT' | translate"></app-info-block>

            <div class="col-md-3 col-sm-3">
                <div *ngIf="contactData" class="about-image-wrapper">
                    <a target="_blank" rel="noopener noreferrer" href="/assets/tmp/modelcv.pdf">
                        <img class="rounded-circle" src="/assets/images/about/about-lawyer-sufakova.png">
                        <span class="full-name">{{fullName()}}</span>
                        <p>
                            {{'ABOUT.LAWYER' | translate}}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
