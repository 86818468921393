import { Component, OnDestroy } from '@angular/core';
import { PageSectionService } from 'app/core/service/page-section.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/internal/Observable';
import { MainMenuSectionId, MAIN_MENU_SECTIONS } from 'app/app-routing.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnDestroy {

  private subscription: Subscription;
  currentSection: string;

  constructor(private pageSectionService: PageSectionService,
              private translateService: TranslateService) {
    this.subscription = this.pageSectionService.currentSection$
    .subscribe(currentSection => this.currentSection = currentSection.id);
  }

  getHeaderSections(): MainMenuSectionId[] {
    return MAIN_MENU_SECTIONS;
  }

  isActive(section: MainMenuSectionId): boolean {
    return this.currentSection === section.id;
  }

  getTranslation(section: MainMenuSectionId): Observable<string> {
    return this.translateService.get(`HEADER.${section.id.toUpperCase()}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
