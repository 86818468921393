<div [id]="getId()">
    <div class="container">
        <div class="row">
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-1.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-1.TEXT' | translate"></app-info-block>
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-2.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-2.TEXT' | translate"></app-info-block>
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-3.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-3.TEXT' | translate"></app-info-block>
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-4.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-4.TEXT' | translate"></app-info-block>
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-5.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-5.TEXT' | translate"></app-info-block>
            <app-info-block class="col-md-3 col-sm-6"
                            [title]="'SERVICES.INFO-BLOCK-6.TITLE' | translate"
                            [text]="'SERVICES.INFO-BLOCK-6.TEXT' | translate"></app-info-block>
        </div>
    </div>
</div>
