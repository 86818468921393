import { Injectable } from '@angular/core';
import { AddressData } from '../domain/address-data';
import { ContactData } from '../domain/contact-data';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AppDataService {

    constructor(private http: HttpClient) {}

    loadAddressData(): Observable<AddressData> {
        return this.http.get<AddressData>('./assets/data/address-data.json').pipe(share());
    }

    loadContactData(): Observable<ContactData> {
        return this.http.get<ContactData>('./assets/data/contact-data.json').pipe(share());
    }

    buildFullAddress(addressData: AddressData): string {
        return `${addressData.street} ${addressData.postcode} ${addressData.city}`;
    }
}
