import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { MainContentComponent } from './features/main-content/main-content.component';

export interface MainMenuSectionId {
  id: string
}

export const HOME_SECTION_ID = 'home';
export const SERVICES_SECTION_ID = 'services';
export const ABOUT_SECTION_ID = 'about';
export const CONTACT_SECTION_ID = 'contact';

export const MAIN_MENU_SECTIONS: MainMenuSectionId[] = [
{ id: HOME_SECTION_ID },
{ id: SERVICES_SECTION_ID },
{ id: ABOUT_SECTION_ID },
{ id: CONTACT_SECTION_ID }
]

/**
 * this routing is only for be able to check if request is valid or it is 404.
 * For valid request there is AppComponents's rooter outlet
 */
export const appRoutes: Routes = [
  { path: '', component: MainContentComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
