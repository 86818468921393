import {Component, OnDestroy} from '@angular/core';
import {MainMenuSection} from 'app/shared/interfaces/main-menu-section';
import {CONTACT_SECTION_ID} from 'app/app-routing.module';
import {AppDataService} from 'app/core/service/app-data.service';
import {ContactData} from 'app/core/domain/contact-data';
import {AddressData} from 'app/core/domain/address-data';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-contact',
    templateUrl: 'contact.component.html',
    styleUrls: ['contact.component.scss'],
})
export class ContactComponent implements MainMenuSection, OnDestroy {

    contactData: ContactData;

    addressData: AddressData;

    /**
     * Custom styles for google map.
     */
    agmMapStyles: any[] = [{'elementType': 'geometry', 'stylers': [{'color': '#f5f5f5'}]}, {
        'elementType': 'labels.icon',
        'stylers': [{'visibility': 'off'}]
    }, {'elementType': 'labels.text.fill', 'stylers': [{'color': '#616161'}]}, {
        'elementType': 'labels.text.stroke',
        'stylers': [{'color': '#f5f5f5'}]
    }, {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [{'color': '#bdbdbd'}]
    }, {'featureType': 'poi', 'elementType': 'geometry', 'stylers': [{'color': '#eeeeee'}]}, {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [{'color': '#757575'}]
    }, {'featureType': 'poi.park', 'elementType': 'geometry', 'stylers': [{'color': '#e5e5e5'}]}, {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [{'color': '#9e9e9e'}]
    }, {'featureType': 'road', 'elementType': 'geometry', 'stylers': [{'color': '#ffffff'}]}, {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [{'color': '#757575'}]
    }, {'featureType': 'road.highway', 'elementType': 'geometry', 'stylers': [{'color': '#dadada'}]}, {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [{'color': '#616161'}]
    }, {'featureType': 'road.local', 'elementType': 'labels.text.fill', 'stylers': [{'color': '#9e9e9e'}]}, {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [{'color': '#e5e5e5'}]
    }, {'featureType': 'transit.station', 'elementType': 'geometry', 'stylers': [{'color': '#eeeeee'}]}, {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [{'color': '#c9c9c9'}]
    }, {'featureType': 'water', 'elementType': 'labels.text.fill', 'stylers': [{'color': '#9e9e9e'}]}];

    // TODO put into one variable
    private subscription1: Subscription;
    private subscription2: Subscription;

    public scrollwheel = false;

    constructor(private appDataService: AppDataService) {

        this.subscription1 = appDataService.loadAddressData().subscribe(addressData => {
            this.addressData = addressData;
        });

        this.subscription2 = appDataService.loadContactData().subscribe(contactData => {
            this.contactData = contactData;
        });
    }

    getId(): string {
        return CONTACT_SECTION_ID
    }

    buildFullAddress(): string {
        return this.appDataService.buildFullAddress(this.addressData);
    }

    getEmailHref(): string {
        return `mailto:${this.contactData.email}`;
    }

    getTelHref(): string {
        return `tel:${this.contactData.phone}`;
    }

    /**
     * opens in new tab window with navigation to contact's address
     */
    getMapNavigationHref(): string {
        if ((navigator.platform.indexOf('iPhone') !== -1)
            || (navigator.platform.indexOf('iPod') !== -1)
            || (navigator.platform.indexOf('iPad') !== -1)) {
            return `maps://maps.google.com/maps?daddr=${this.addressData.latitude},${this.addressData.longitude}&amp;ll=`;
        } else {
            return `http://maps.google.com/maps?daddr=${this.addressData.latitude},${this.addressData.longitude}&amp;ll=`;
        }
    }

    ngOnDestroy(): void {
        this.subscription1.unsubscribe();
        this.subscription2.unsubscribe();
    }

    onClick() {
        this.scrollwheel = !this.scrollwheel;
     }
}
