import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-app',
  templateUrl: 'app.component.html',
})
export class AppComponent {

  constructor(private title: Title, private translateService: TranslateService) {
    this.translateService.get('PAGE.TITLE').subscribe(titleTranslation => {
      this.title.setTitle(titleTranslation);
    });
  }
}
