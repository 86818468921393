import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { FeaturesModule } from './features/features.module';

/**
 * Angular main module which is bootstrapping everything via AppComponent.
 * App folder contains main component AppComponent, main menu component and main menu rooting
 * Shared folder contains shared stuffs like components, directives, pipes, styles.
 * Core module contains singleton services, utils and data classes.
 * Features module contains feature modules - standalone loosely coupled modules which handle
 * one feature.
 */
@NgModule({
  imports: [BrowserModule, CoreModule, SharedModule, FeaturesModule,
    AppRoutingModule, BrowserAnimationsModule],
  declarations: [
    AppComponent
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
}

