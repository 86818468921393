import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { MainContentComponent } from './main-content/main-content.component';
import { ServicesComponent } from './services/services.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CoreModule } from 'app/core/core.module';

@NgModule({
  imports: [SharedModule, CoreModule],
  declarations: [
    HomeComponent,
    ContactComponent,
    AboutComponent,
    MainContentComponent,
    ServicesComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent
  ]
})
export class FeaturesModule {
}
