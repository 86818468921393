<div [id]="getId()" class="container-fluid">

    <!-- TODO loading progress -->
    <div *ngIf="contactData && addressData" class="row contact-info-wrapper">
        <app-contact-info-block class="col-md-4 col-sm-6 col-xs-12" [title]="'CONTACT.PHONE-TITLE' | translate"
            [text]="contactData.phone" [href]="getTelHref()" [target]="'_self'" icon="fi-telephone">
        </app-contact-info-block>
        <app-contact-info-block class="col-md-4 col-sm-6 col-xs-12" [title]="'CONTACT.E-MAIL-TITLE' | translate"
            [text]="contactData.email" [href]="getEmailHref()" [target]="'_self'" icon="fi-at-sign">
        </app-contact-info-block>
        <app-contact-info-block class="col-md-4 col-sm-6 col-xs-12" [title]="'CONTACT.ADDRESS-TITLE' | translate"
            [text]="buildFullAddress()" [href]="getMapNavigationHref()" [target]="'_blank'" icon="fi-marker">
        </app-contact-info-block>
    </div>

    <div class="row map-wrapper">
        <agm-map *ngIf="addressData" (mapClick)="onClick()" gestureHandling="auto" [scrollwheel]="scrollwheel" [zoom]="15" [latitude]="addressData.latitude"
            [longitude]="addressData.longitude" [styles]="agmMapStyles">
            <agm-marker [latitude]="addressData.latitude" [longitude]="addressData.longitude"></agm-marker>
        </agm-map>
    </div>
</div>
