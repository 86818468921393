import {Component} from '@angular/core';
import {MainMenuSection} from 'app/shared/interfaces/main-menu-section';
import {SERVICES_SECTION_ID} from 'app/app-routing.module';

@Component({
    selector: 'app-services',
    templateUrl: 'services.component.html',
    styleUrls: ['services.component.scss']
})
export class ServicesComponent implements MainMenuSection {

    constructor() {
    }

    getId(): string {
        return SERVICES_SECTION_ID;
    }

}
