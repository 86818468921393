<nav class="navbar navbar-expand-md">

    <a class="navbar-brand" [routerLink]="['./']" fragment="home">
        <img src="/assets/images/logo.png" alt="">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
            <i class="step fi-list"></i>
        </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-link" [class.active]="isActive(section)" *ngFor="let section of getHeaderSections()">
                <a [routerLink]="['./']"
                   [fragment]="section.id">{{getTranslation(section) | async}}</a>
            </li>
        </ul>
    </div>
</nav>