import { Component } from '@angular/core';
import { MainMenuSection } from 'app/shared/interfaces/main-menu-section';
import { HOME_SECTION_ID } from 'app/app-routing.module';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements MainMenuSection {
  getId(): string {
    return HOME_SECTION_ID;
  }
}
