import { Component } from '@angular/core';
import { AppDataService } from 'app/core/service/app-data.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {

  companyName$: Observable<string>;

  constructor(public appDataService: AppDataService) {
    this.companyName$ = this.appDataService.loadContactData().pipe(map(data => data.companyName));
  }

  getYear(): number {
    return new Date().getFullYear();
  }
}
