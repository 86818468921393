import { Component } from '@angular/core';

@Component({
  templateUrl: 'page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss'],
})
export class PageNotFoundComponent {

}
