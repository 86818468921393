<a [href]="href" [target]="target" class="contact-item-wrapper">

    <span>
        <i class="step {{icon}}"></i>
    </span>

    <span class="title">
        <h4>{{title}}</h4>
    </span>

    <span class="text">
        <p>{{text}}</p>
    </span>
</a>
