import {
  MissingTranslationHandler as NgxMissingTranslationHandler, MissingTranslationHandlerParams
} from '@ngx-translate/core';

/**
 * Custom missing translation handler. In future this handler can try to load these translations from backend
 */
export class MissingTranslationHandler extends NgxMissingTranslationHandler {

  handle(params: MissingTranslationHandlerParams): any {
    return `???${params.key}???`;
  }
}
