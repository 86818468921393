import { NgModule, Optional, SkipSelf, isDevMode } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SharedModule } from 'app/shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AppDataService } from './service/app-data.service';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler as NgxMissingTranslationHandler } from '@ngx-translate/core';

import { Logger, Level, Options } from 'angular2-logger/core';
import { MissingTranslationHandler } from './translate/missing-translation-handler';
import { PageSectionService } from './service/page-section.service';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [SharedModule, HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDUzZdi2LgA2zI5o2bBSqJvuV-bEEkfZpU',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Logger,
    Options,
    AppDataService,
    { provide: NgxMissingTranslationHandler, useClass: MissingTranslationHandler },
    PageSectionService
  ]
})
/**
 * This module provides all services that are needed.
 */
export class CoreModule {

  /**
   * configures log level globally for whole application
   * @param logger
   * @param translate
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule,
    private translate: TranslateService,
    private logger: Logger) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('sk');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('sk');

    if (!isDevMode()) {
      this.logger.level = Level.ERROR;
    } else {
      this.logger.level = Level.LOG;
    }
  }
}
