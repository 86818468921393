import { Component, OnDestroy } from '@angular/core';
import { MainMenuSection } from 'app/shared/interfaces/main-menu-section';
import { ABOUT_SECTION_ID } from 'app/app-routing.module';
import { AppDataService } from 'app/core/service/app-data.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContactData } from 'app/core/domain/contact-data';

@Component({
  selector: 'app-about',
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.scss'],
})
export class AboutComponent implements MainMenuSection, OnDestroy {

  private subscription: Subscription;
  public contactData: ContactData;

  constructor(private appDataService: AppDataService) {
    this.subscription = this.appDataService.loadContactData().subscribe(contactData => {
      this.contactData = contactData;
    });
  }

  getId(): string {
    return ABOUT_SECTION_ID;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fullName(): string {
    return `${this.contactData.title} ${this.contactData.name} ${this.contactData.surname}`;
  }
}
