<div class="title">
    <h3>{{title}}</h3>
</div>

<div class="separator"></div>

<div class="text">
    <p>{{text}}</p>
</div>

