import { Directive, AfterViewInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Logger } from 'angular2-logger/core';
import { MAIN_MENU_SECTIONS, HOME_SECTION_ID } from 'app/app-routing.module';
import { PageSectionService } from 'app/core/service/page-section.service';

// for importing jquery
declare var $: any;

/**
 * enhances basic angular router navigation with animation (smooth scroll when anchor clicked)
 * when user scrolls manually this directive changes url according scrolled section
 */
@Directive({
    selector: '[appScrollBootstrap]'
})
export class ScrollBootstrapDirective implements AfterViewInit {

    /**
     * true when scrolling animation in progress
     */
    private navigationAnimationInProgress = false;

    /**
     * current element which is visible (the most part)
     */
    private currentScrolledElement: any;

    constructor(private logger: Logger, private router: Router,
                private pageSectionService: PageSectionService) { }

    ngAfterViewInit(): void {

        /**
         * this is for fixing when scrolling from home to first section and then click back
         */
        if (window.location.href.indexOf('#') === -1) {
            window.history.pushState(null, '#' + HOME_SECTION_ID, '#' + HOME_SECTION_ID);
            this.currentScrolledElement = document.getElementById(HOME_SECTION_ID);
            this.pageSectionService.sectionChanged(HOME_SECTION_ID);
        } else {
            const fragment = window.location.href.substring(window.location.href.indexOf('#') + 1);
            this.currentScrolledElement = $(document.getElementById(fragment));

            // scroll function must be called
            // after page is layouted and rendered
            setTimeout(() => {
                this.scrollToCurrentElement(fragment);
            }, 500);
        }

        window.addEventListener('scroll', () => {
            if (!this.navigationAnimationInProgress && !this.isScrolledIntoView(this.currentScrolledElement)) {
                for (let i = 0; i < MAIN_MENU_SECTIONS.length; i++) {
                    const section = MAIN_MENU_SECTIONS[i].id;
                    if (this.isScrolledIntoView(document.getElementById(section))) {
                        window.history.pushState(null, '#' + section, '#' + section);
                        this.logger.debug('setting section ' + section + ' according scrolled position');
                        this.currentScrolledElement = document.getElementById(section);
                        this.pageSectionService.sectionChanged(section);
                        break;
                    }
                }
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                let fragment = event.url.replace('/', '').replace('#', '');
                if (fragment.length === 0) {
                    fragment = HOME_SECTION_ID;
                }
                this.logger.debug('fragment:' + fragment);

                this.currentScrolledElement = $(document.getElementById(fragment));

                this.scrollToCurrentElement(fragment);

                // hide menu when in mobile
                const el = $('.navbar-collapse.collapse.show');
                el.slideUp('slow', () => {
                    el.css('display', '');
                    el.removeClass('show');
                })
            }
        });
    }

    private isScrolledIntoView(elem: any): boolean {

        const margin = $(document.body).height() * 0.2;

        const docViewTop = $(window).scrollTop();
        const docViewBottom = docViewTop + $(elem).height();

        const elemTop = $(elem).offset().top;
        const elemBottom = elemTop + $(elem).height();

        return ((elemBottom - margin <= docViewBottom) && (elemTop + margin >= docViewTop));
    }

    private scrollToCurrentElement(fragment: string): void {
        this.navigationAnimationInProgress = true;

        $([document.documentElement, document.body]).animate({
            scrollTop: this.currentScrolledElement.offset().top - parseInt($(document.body).css('margin-top'), 10),
        }, { duration: 700,
             easing: 'swing',
             complete: () => {
            this.navigationAnimationInProgress = false;
            this.pageSectionService.sectionChanged(fragment);
           }
        });
    }
}
