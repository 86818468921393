import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollBootstrapDirective } from './directive/scroll-bootstrap.directive';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { ContactInfoBlockComponent } from './components/contact-info-block/contact-info-block.component';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule,
              AgmCoreModule,
              TranslateModule,
              RouterModule],
    declarations: [ScrollBootstrapDirective,
                   InfoBlockComponent,
                   ContactInfoBlockComponent],
    exports: [CommonModule,
              ScrollBootstrapDirective,
              InfoBlockComponent,
              ContactInfoBlockComponent,
              AgmCoreModule,
              TranslateModule,
              RouterModule]
})
export class SharedModule { }
