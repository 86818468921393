import { Injectable } from '@angular/core';
import { MainMenuSectionId, MAIN_MENU_SECTIONS, HOME_SECTION_ID } from 'app/app-routing.module';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class PageSectionService {

    readonly currentSection$: BehaviorSubject<MainMenuSectionId> =
    new BehaviorSubject<MainMenuSectionId>(this.findSection(HOME_SECTION_ID));

    public sectionChanged(id: string) {
        this.currentSection$.next(this.findSection(id));
    }

    private findSection(id: string): MainMenuSectionId {
        return MAIN_MENU_SECTIONS.find(section => section.id === id);
    }
}
